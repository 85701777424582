body {
  background-color: #cadbff; /* Light blue color */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

/* Add this to your global styles (index.css or any global CSS file) */
.full-height {
    height: 100vh;
  }

.full-height h3{
    padding-top: 10vh;
}

.full-height h2{
  padding-top: 4vh;
}
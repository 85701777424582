.the-lessons-section {
  text-align: center;
  height: 80vh; /* Set the height of the section to 100% of the viewport height */
}

.plan-container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

#swim-lessons {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  min-height: 100vh; /* Full viewport height for proper centering */
  padding: 0px;
}


.lesson-card {
  border: 3px solid #007bff;
  border-radius: 12px;
  padding: 5vw; /* Increased padding for a larger card */
  max-width: 30vw; /* Increased maximum width */
  min-width: 22vw; /* Set a minimum height to make them taller */

  min-height: 15vw; /* Set a minimum height to make them taller */
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  font-size: 1.6rem; /* Larger text size */
}


.lesson-price {
  font-weight: bold;
  color: #007bff;
}

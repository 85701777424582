/* Centers the entire page content */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
    padding: 20px;
    background-color: #f4f7fb; /* Light background color */
  }
  
  /* Main section for the form */
  .form-container {
    width: 80%;
    max-width: 600px; /* Max width for better readability */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  /* Heading */
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Form elements */
  label {
    font-size: 1.2rem;
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 1.1rem;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  button {
    padding: 14px 20px;
    background-color: #4CAF50; /* Green background for the submit button */
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
  }
  
  /* Styling the checkboxes and labels */
  .checkbox-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .checkbox-section label {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .checkbox-section input {
    margin-bottom: 10px;
  }
  
  /* Optional: Adjust spacing for time slots */ 

  .lesson-type-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }

  .lesson-type-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }


  .time-slot-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .time-slot-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .time-slot-list input[type="checkbox"] {
    margin-right: 10px;
  }
  
  /* Additional Info Section */
  .additional-info {
    margin-bottom: 20px;
  }
  
  .additional-info textarea {
    resize: vertical;
    height: 150px;
  }
  
  /* Optional: Make everything more responsive */
  @media (max-width: 768px) {
    .form-container {
      width: 90%;
    }
    button {
      font-size: 1rem;
    }
  }
  
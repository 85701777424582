/* styles/NavBar.css */
.navbar {
  background-color: #0080ff; /* Blue color for the navbar */
  height: 10vh; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  position: fixed; /* Make the navbar fixed at the top */
  justify-content: space-evenly; /* Evenly space items horizontally */
  top: 0; /* Position it at the top of the viewport */
  width: 100%; /* Make it span the entire width of the viewport */
  z-index: 1000; /* Ensure it's above other content */
}

.navbar ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  margin-right: 7vw; /* Adjust spacing between navbar items */
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2vw;
}

@media screen and (max-width: 768px) {
  .navbar ul li a {
    font-size: 3.0vw;
  }
}
/* Centers the calendar in the middle of the page */
.the-calendar-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

/* Styles the calendar to be medium-sized */
.react-calendar {
  width: 70vw; /* Adjust the width to your desired size */
  height: 65vh;
  max-width: 100%; /* Ensure it is responsive */
  border: 1px solid #000000; /* Optional: adds a border */
  border-radius: 8px; /* Optional: gives rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
  background-color: rgb(203, 232, 255); /* Optional: background color */
}

/* Individual day tiles (date buttons) */
.react-calendar__tile {
  border-radius: 4px; /* Rounds the corners of individual day tiles */
  height: 10vh; /* Adjust the height of date tiles */
  font-size: 2.5vh; /* Adjust the font size of date text */
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* Ensures the date text is centered */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for individual date tiles */
.react-calendar__tile:hover {
  background-color: rgb(97, 187, 255) !important; /* Slight background change on hover */
  border-radius: 4px; /* Ensure corners remain rounded */
}

/* Style for the navigation buttons (< and >) */
.react-calendar__navigation button {
  border-radius: 50%; /* Makes the navigation buttons slightly rounded */
  padding: 10px; /* Adds padding to increase the size of the buttons */
  background-color: #c5c5c5; /* Light background for buttons */
  border: none; /* Removes the default border */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to the buttons */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  font-size: 2.5vh; /* Adjusts the font size of the navigation buttons */
}

/* Style for the month/year label */
.react-calendar__navigation__label {
  font-weight: bold; /* Make the label text bold */
  color: #333; /* Change the text color */
  text-transform: uppercase; /* Optional: make the text uppercase */
  border-radius: 0 !important; /* Force the border-radius to 0, if necessary */
}

/* Optional: hover effect for the navigation buttons */
.react-calendar__navigation button:hover {
  background-color: rgb(97, 187, 255) !important; /* Slight background change on hover */
}


.react-calendar__month-view__weekdays__weekday {
  font-size: 2vh; /* Adjust the font size of the weekday labels */
  font-weight: bold; /* Make the weekday labels bold */
  color: #333; /* Change the text color */
  text-transform: uppercase; /* Make the text uppercase */
  padding: 5px 0; /* Add padding for spacing */
  background-color: rgb(97, 187, 255);
}



/* Centers the header text */
h1 {
  text-align: center;
  margin-bottom: 20px;
}
